//
//  Sweetalert2
//

.swal2-footer {
  border-top: 1px dashed #eee !important;
}

.swal2-popup {
  width: 25rem !important;
}

.swal2-title {
  font-size: 1.35rem !important;
}

.swal2-content {
  font-size: 0.875rem !important;
}

.swal2-icon {
  &.swal2-question,
  &.swal2-info,
  &.swal2-error,
  &.swal2-warning {
    width: 4rem;
    height: 4rem;
    .swal2-icon-content{
      font-size: 3rem;
    }
  }
  &.swal2-question {
    .swal2-icon-content {
      margin-top: 0.6rem;
      animation: swal2-animate-question-mark 0.8s;
    }
  }

  &.swal2-info,
  &.swal2-warning {
    .swal2-icon-content {
      margin-top: 0.6rem;
      animation: swal2-animate-i-mark 0.8s;
    }
  }
  &.swal2-error {
    .swal2-x-mark {
      .swal2-x-mark-line-left,
      .swal2-x-mark-line-right {
        top: 1.9rem !important;
        width: 2.5rem !important;
        height: 0.3rem !important;
      }
      .swal2-x-mark-line-left {
        left: 0.9em !important;
        transform: rotate(40deg) !important;
      }
      .swal2-x-mark-line-right {
        right: 0.9em !important;
        transform: rotate(-40deg) !important;
      }
    }
  }
}

@keyframes swal2-animate-i-mark {
  0% {
    transform: rotateZ(45deg);
    opacity: 0;
  }
  25% {
    transform: rotateZ(-25deg);
    opacity: 0.4;
  }
  50% {
    transform: rotateZ(15deg);
    opacity: 0.8;
  }
  75% {
    transform: rotateZ(-5deg);
    opacity: 1;
  }
  100% {
    transform: rotateX(0);
    opacity: 1;
  }
}

@keyframes swal2-animate-question-mark {
  0% {
    transform: rotateY(-360deg);
  }
  100% {
    transform: rotateY(0);
  }
}
