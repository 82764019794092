
// Forms

// checkbox input right

.form-check-right {
  padding-left: 0;
  display: inline-block;
  padding-right: $form-check-padding-start;
  .form-check-input {
    float: right;
    margin-left: 0;
    margin-right: $form-check-padding-start * -1;
  }
  .form-check-label {
    display: block;
  }
}

.form-check {
  position: relative;
  text-align: left /*rtl: right*/;
}

.form-check-label {
  cursor: pointer;
  margin-bottom: 0;
}

.form-floating {
  > .form-control {
    &:focus {
      padding-top: 1.2rem !important;
      padding-bottom: 1rem !important;
      + label {
        top: -15px !important;
      }
    }
  }
  .form-control {
    height: calc(1rem + 1px) !important;
  }
  > label {
    top: -7px !important;
  }
  > .form-select {
    height: auto !important;
    padding-top: 1rem !important;
    padding-bottom: 0.5rem !important;
    + label {
      top: -15px !important;
    }
  }
}

.form-floating > .form-control:not(:placeholder-shown) {
  padding-top: 1.2rem !important;
  padding-bottom: 1rem !important;
  + label {
    top: -15px !important;
  }
}
