
//General

html {
    position: relative;
    min-height: 100%;
}

.h1,
.h2,
.h3,
.h4,
.h5,
.h6,
h1,
h2,
h3,
h4,
h5,
h6 {
    color: var(--#{$prefix}body-color);
    font-family: $font-family-secondary;
}

a {
    text-decoration: none !important;
}

// blockquote

.blockquote-reverse {
    border-left: 0;
    border-right: 4px solid var(--#{$prefix}border-color);
    text-align: right;
}

.blockquote-footer{
    font-size: .7rem;
}

// row

.row{
    > * {
        position: relative;
    }
  }
  