
// _sortablejs.scss


.sortable-handle {
    cursor: move;
    padding: 0.25rem;
}

.sortable-handle > i {
    font-size: 1rem;
    color: var(--#{$prefix}secondary-color);
}

.sortable {
    &.rich-list-bordered {
        .sortable,
        .sortable-item:not(:first-of-type) {
            margin-top: 0.5rem;
        }
    }
    .sortable{
        padding-left: 1.25rem;
    }
}

.sortable {
    display: flex;
    flex-direction: column;
}
