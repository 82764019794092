// rich-list.scss

.rich-list {
    position: relative;
    display: flex;
    flex-direction: column;
    list-style: none;
    margin: 0;
    padding: 0;
}

.rich-list-item {
    position: relative;
    display: flex;
    flex-direction: row;
    align-items: center;
    background: 0 0;
    padding: 0.75rem;
    margin: 0;
    cursor: default;
    text-decoration: none !important;
}

.rich-list-content {
    flex: 1;
    display: flex;
    flex-direction: column;
}

.rich-list-title {
    font-size: 0.75rem;
    margin-bottom: 0;
    padding: 0;
}

.rich-list-subtitle {
    color: var(--#{$prefix}secondary-color);
}

.rich-list-bordered {
    .rich-list-item {
        border: 1px dashed var(--#{$prefix}border-color);
        border-radius: $border-radius;
        margin-bottom: 5px;
    }
}
.rich-list-append,
.rich-list-prepend {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    color: var(--bs-text-level-1);
}
.rich-list-prepend {
    margin-right: 0.5rem;
}
.rich-list-append {
    margin-left: 0.5rem;
}

.rich-list-action {
    .rich-list-item {
        cursor: pointer;
        &:hover,
        &.active,
        &:active {
            background: var(--#{$prefix}light);
            .rich-list-title {
                color: $primary;
            }
        }
        &.disabled,
        &:disabled {
            background: var(--#{$prefix}secondary-bg);
            opacity: 0.65;
            pointer-events: none;
            cursor: default;
        }
    }
}
.rich-list-flush {
    .rich-list-item {
        border-bottom: 1px dashed var(--#{$prefix}border-color);
    }
}
