 
// pagination

// Pagination rounded (Custom)
.pagination-rounded {
    .page-link {
        border-radius: 30px !important;
        margin: 0 3px;
        border: none;
        text-align: center;
        // height: 35px;
    }
  }