
// Widgets

.widgets-nav {
    .nav-item{
        border: 1px solid var(--#{$prefix}border-color);
        border-left: transparent;
        border-radius: 0;
        &:first-child{
            border-left: 1px solid var(--#{$prefix}border-color);
            border-top-left-radius: $border-radius;
            border-bottom-left-radius: $border-radius;
        }
        &:last-child{
            border-top-right-radius: $border-radius;
            border-bottom-right-radius: $border-radius;
        }
    }
}