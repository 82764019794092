
//
// Bootstrap touchspin
//


.bootstrap-touchspin{
    &.input-group{
      &>.input-group-prepend{
        &>.btn, &>.input-group-text{
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
        }
      }
    }
  
    &.input-group{
      &>.input-group-append{
        &>.btn, &>.input-group-text{
          border-top-left-radius: 0;
          border-bottom-left-radius: 0;
        }
      }
    }
  }

  .bootstrap-touchspin{
    .bootstrap-touchspin-vertical-button-wrapper{
      width: 29px;
    }
  }
